<template>
  <div id="material-view">
      <div class="t-head jac mb10">
          <h1>农投品大屏</h1>
          <full-screen  style="position: absolute;right: 20px"></full-screen>
      </div>
    <div class="bg-container">

      <div class="main-content">

        <div class="card-item">
          <dv-border-box-12>
            <div class="card-item-title">
<!--              <el-select v-model="baseItem.id" placeholder="请选择" @change="handleBaseChange">-->
<!--                <el-option-->
<!--                  v-for="item in baseOptions"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--                ></el-option>-->
<!--              </el-select>-->
              <h1>种植基地</h1>
            </div>
            <dv-decoration-2 style="width:100%;height:5px;"/>
            <ve-bmap
              ref="mapRef"
              class="card-item-content"
              height="95%"
              :settings="chartSettings"
              :series="chartSeries"
              :tooltip="chartTooltip"
              :events="chartEvents"
            ></ve-bmap>
          </dv-border-box-12>
        </div>

        <dv-decoration-4 style="width:5px;height:100%;"/>

        <div class="card-item" style=" width: 60%;min-width: 60%;max-width: 60%;">
          <div class="class-name">
            <dv-decoration-7 style="height:30px;margin:0 auto;"><div class="f20 p10">农投品</div></dv-decoration-7>
          </div>
          <div>
            <dv-border-box-1>
              <div class="chart-box">
                <ve-pie
                  :data="data1.pieChartData"
                  :settings="pieChartSettings"
                  :colors="chartColors"
                  :grid="grid"
                  :extend="pieChartExtend"
                  height="100%"
                ></ve-pie>
              </div>
              <div class="chart-box">
                <ve-histogram
                  :data="data1.barChartData"
                  height="100%"
                  :settings="barChartSetting1"
                  :colors="chartColors"
                  :grid="barGrid"
                  :extend="barChartExtend"
                ></ve-histogram>
              </div>
            </dv-border-box-1>
          </div>
          <div class="chart-box">
            <el-table
              class="data-table"
              :data="data1.tableData"
              border
              style="width: 100%;background:transparent;"
              height="100%"
              :row-style="tableRowStyle"
              :header-cell-style="tableHeaderColor"
            >
              <el-table-column align="center" prop="name" label="名称"></el-table-column>
              <el-table-column align="center" label="休药期/间歇期">
                <template slot-scope="scope">
                  <flip-down :endDate="scope.row.value" :timeUnit="['天','时','分','秒']"/>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <dv-decoration-4 style="width:5px;height:100%;"/>
<!--        <div class="card-item">-->
<!--          <div class="class-name">-->
<!--            <dv-decoration-7 style="height:30px;margin:0 auto;">肥料/饲料</dv-decoration-7>-->
<!--          </div>-->
<!--          <div>-->
<!--            <dv-border-box-6>-->
<!--              <div class="chart-box">-->
<!--                <ve-pie-->
<!--                  :data="data2.pieChartData"-->
<!--                  :settings="pieChartSettings"-->
<!--                  :colors="chartColors"-->
<!--                  :grid="grid"-->
<!--                  :extend="pieChartExtend"-->
<!--                  height="100%"-->
<!--                ></ve-pie>-->
<!--              </div>-->
<!--              <div class="chart-box">-->
<!--                <ve-histogram-->
<!--                  :data="data2.barChartData"-->
<!--                  height="100%"-->
<!--                  :settings="barChartSetting"-->
<!--                  :colors="chartColors"-->
<!--                  :grid="barGrid"-->
<!--                  :extend="barChartExtend"-->
<!--                ></ve-histogram>-->
<!--              </div>-->
<!--            </dv-border-box-6>-->
<!--          </div>-->
<!--          <div class="chart-box">-->
<!--            <el-table-->
<!--              class="data-table"-->
<!--              :data="data2.tableData"-->
<!--              border-->
<!--              style="width: 100%;background:transparent;"-->
<!--              height="100%"-->
<!--              :row-style="tableRowStyle"-->
<!--              :header-cell-style="tableHeaderColor"-->
<!--            >-->
<!--              <el-table-column align="center" prop="name" label="名称"></el-table-column>-->
<!--              <el-table-column align="center" label="间隔期">-->
<!--                <template slot-scope="scope">-->
<!--                  <flip-down :endDate="scope.row.value" :timeUnit="['天','时','分','秒']"/>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import screen from "@/api/screen.js";
import {deepClone} from "@/util/util";
import {mapConfig} from "./custom_map_config.js";
import FlipDown from "vue-flip-down";
import api from "../../../util/extra-api";
export default {
  components: {
    FlipDown
  },
  data() {
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        mapStyle: {styleJson: mapConfig}
      }
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        return `${params.marker}${params.value[2]}<br>地址:${params.value[3]}`;
      }
    };
    const _this = this;
    this.chartEvents = {
      click: v => {
        let point = new window.BMap.Point(v.value[0], v.value[1]);
        _this.handleCurrent(point);
        _this.handleDetailData(v.value[4]);
      }
    };
    return {
        time:'1651046576000',
      baseItem: {},
      baseOptions: [],
      baseInfo: [],
      chartSeries: null,
      mapLabel: null,
      data1: {
        pieChartData: {
          columns: ["name", "value"],
          rows: []
        },
        barChartData: {
          columns: ["name", "value"],
          rows: []
        },
        tableData: []
      },
      data2: {
        pieChartData: {
          columns: ["name", "value"],
          rows: []
        },
        barChartData: {
          columns: ["name", "value"],
          rows: []
        },
        tableData: []
      },
      chartColors: [
        "#37a2da",
          "#ff9f7f",
        "#32c5e9",
        "#67e0e3",
        "#9fe6b8",
        "#ffdb5c",

        "#fb7293",
        "#e062ae",
        "#e690d1",
        "#e7bcf3",
        "#9d96f5",
        "#8378ea",
        "#96bfff"
      ],
      pieChartSettings: {
        // roseType: "radius",
        // offsetY: "60%"
      },
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100%"
      },
      pieChartExtend: {
        series: {
          radius: "50%"
        },
        legend: {
          top: "2%",
          textStyle: {
            color: "#fff"
          }
        }
      },
      barChartSetting: {
        labelMap: {
          value: "使用量(kg)"
        }
      },
      barChartSetting1: {
        labelMap: {
          value: "使用量(g)"
        }
      },
      barChartExtend: {
        series: {
          type: "bar",
          barMaxWidth: "30",
          itemStyle: {
            normal: {
              //每根柱子颜色设置
              color: function (params) {
                let colorList = [
                  "#37a2da",
                    "#ff9f7f",
                  "#32c5e9",
                  "#67e0e3",
                  "#9fe6b8",
                  "#ffdb5c",

                  "#fb7293",
                  "#e062ae",
                  "#e690d1",
                  "#e7bcf3",
                  "#9d96f5",
                  "#8378ea",
                  "#96bfff"
                ];
                return colorList[params.dataIndex];
              }
            }
          }
        },
        legend: {
          show: false,
          top: "2%",
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          axisLabel: {
            show: false,
            textStyle: {
              color: "#fff"
            }
          }
        },
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        }
      },
      barGrid: {
        top: 30,
        bottom: "2%"
      },
      lineChartExtend: {
        legend: {
          top: "2%",
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        },
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff"
            }
          }
        }
      },
      lineGrid: {
        bottom: "2%"
      }
    };
  },
  computed: {},
  created() {
  },
  mounted() {
      // if(this.$route.query.fullScreen === 1){
      //     setTimeout(() =>{
      //         this.$router.push({name:'farmRecords',query:{fullScreen:1}})
      //     },30000)
      // }
    this.initData();
  },
  methods: {
    initData() {
      api.get("/v1/pc/plantingScreen/oneChart1",{companyId:localStorage.getItem("company_id")}).then(response => {
        const responseData = response.data;
        const data = deepClone(responseData);
        this.baseInfo = deepClone(responseData);
        this.baseItem = data;
        responseData.forEach(item => {
          this.baseOptions.push({
            id: item.id,
            name: item.name
          });
        });
        let baseData = this.convertData(data);
        this.drawMap(baseData, data);
      });
    },
    drawMap(baseData, data) {
      let echarts = this.$refs.mapRef.echarts;
      echarts.clear();
      let arr = [];
      let pointArr = [];
      baseData.forEach((item, index) => {
        arr.push({
          type: "effectScatter",
          coordinateSystem: "bmap",
          zlevel: 2,
          rippleEffect: {
            //涟漪特效
            period: 4, //动画时间，值越小速度越快
            brushType: "stroke", //波纹绘制方式 stroke, fill
            scale: 4 //波纹圆环最大限制，值越大波纹越大
          },
          symbol: "circle",
          itemStyle: {
            normal: {
              color: "#19d4ae"
            }
          },
          data: item
        });
      });
        let t = setTimeout(() => {
            data.forEach(item => {
                pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
            });
            this.chartSeries = arr;
            echarts.setOption({
                bmap: this.chartSettings.bmap,
                series: this.chartSeries
            });
            this.handleCurrent(pointArr[0]);
            this.handleDetailData(data[0].id);
            let map = echarts
                .getModel()
                .getComponent("bmap")
                .getBMap();
            let view = map.getViewport(eval(pointArr));
            let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
            let centerPoint = view.center;
            map.centerAndZoom(centerPoint, mapZoom);
        },500)
    },
    convertData(data) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let formattedData = [
          [
            data[i].longitude,
            data[i].latitude,
            data[i].baseName,
            data[i].addressName,
            data[i].id,
          ]
        ];
        result.push(formattedData);
      }
      return result;
    },
    handleDetailData(id) {
      api.get("/v1/pc/plantingScreen/twoChart1",{companyId:localStorage.getItem("company_id"),baseId:id}).then(response => {
        const data = response.data;
        // let aaa = [{
        //     name: '青霉素',
        //     value: 500
        // },{
        //     name: '阿莫西林',
        //     value: 1000
        // },{
        //     name: '阿莫西林1',
        //     value: 1000
        // },{
        //     name: '阿莫西林2',
        //     value: 1000
        // }]
        this.data1 = {
          pieChartData: {
            columns: ["name", "value"],
            rows: data.pie
             // rows: aaa
          },
          barChartData: {
            columns: ["name", "value"],
            rows: data.bar
          },
          tableData: data.tab.filter(m => m.value > Date.parse(new Date()))
        };
        this.data2 = {
          pieChartData: {
            columns: ["name", "value"],
            rows: data.pie
          },
          barChartData: {
            columns: ["name", "value"],
            rows: data.bar
          },
          tableData: data.tab
        };
      });
    },
    handleBaseChange(val) {
      let baseInfo = deepClone(this.baseInfo);
      let index = baseInfo.findIndex(item => {
        return item.id === val;
      });
      let data = baseInfo[index];
      let baseData = this.convertData(data.son_process);
      this.drawMap(baseData, data.son_process);
    },
    handleCurrent(point) {
      let echarts = this.$refs.mapRef.echarts;
      let map = echarts
        .getModel()
        .getComponent("bmap")
        .getBMap();
      map.removeOverlay(this.mapLabel);
      // let point = new window.BMap.Point(lng, lat);
      let opts = {
        position: point,
        offset: new BMap.Size(-35, -45)
      };
      this.mapLabel = new window.BMap.Label("当前基地", opts);
      this.mapLabel.setStyle({
        color: "#E6A23C",
        fontSize: "16px",
        border: "1px solid #E6A23C",
        backgroundColor: "rgba(0,0,0,1)"
      });
      map.addOverlay(this.mapLabel);
    },
    //设置表格行的样式
    tableRowStyle({row, rowIndex}) {
      if (rowIndex % 2 == 1) {
        return "background-color:rgb(0, 59, 81);color:#fff";
      } else {
        return "background-color:rgb(10, 39, 50);color:#fff";
      }
    },
    //设置表头行的样式
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return "background-color: rgb(0, 186, 255);color:#fff;font-wight:500;text-align:center";
    }
  }
};
</script>
<style lang="scss">
#material-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  .bg-container {
    min-height: 100vh;

    background-image: url("./img/bg.png");
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;

    .main-content {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 15px 5px;
    }

    .card-item {
      flex: 1;
      width: 31%;
      min-width: 31%;
      max-width: 31%;
      background: transparent;
      margin-bottom: 10px;

      .card-item-title {
        padding: 10px 20px;
        background: transparent;
        text-align: center;
        font-size: 18px;
        color: #fff;
        font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei",
        "微软雅黑", Arial, sans-serif;

        .el-input__inner {
          background: transparent !important;
          color: #fff;
        }
      }

      .card-item-content {
        padding: 15px 10px;
      }
    }

    .map-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .map {
        flex: 1;
        padding-bottom: 20px;
        box-sizing: border-box;
      }
    }

    .class-name {
      color: #fff;
      text-align: center;
      margin-bottom: 10px;
    }

    .chart-box {
      width: 100%;
      height: 28vh;
      margin-bottom: 10px;

      .data-table {
        .gutter {
          background-color: rgb(0, 186, 255);
        }

        .el-table__body-wrapper::-webkit-scrollbar {
          width: 17px;
          height: 17px;
        }

        //滚动条的滑块
        .el-table__body-wrapper::-webkit-scrollbar-thumb {
          background-color: #37a2da;
          border-radius: 6px;
        }

        tbody tr:hover > td {
          background-color: #ecf5ff !important;
          color: #030409 !important;

          .vue-countdown-component .time-unit {
            color: #030409;
          }
        }

        .vue-countdown-component .time-unit {
          color: #fff;
        }
      }
    }
  }
}
</style>
